<template>
  <div id="app">
    <a-config-provider :locale="zh_CN">
      <router-view/>
    </a-config-provider>
    <div @click="hideLoading" v-show="loading" class="loading"><a-spin tip="Loading..." /></div>
    <a-back-top />
  </div>
</template>

<script>
import zh_CN from 'ant-design-vue/lib/locale-provider/zh_CN';
import moment from 'moment';
import 'moment/locale/zh-cn';
moment.locale('zh-cn');
import { mapState } from 'vuex'
export default {
  data() {
    return {
      zh_CN,
    }
  },
  computed: {
    ...mapState({
      loading: state => state.loading
    })
  },
}
</script>

<style lang="scss" scoped>
//#app {
//  font-family:
//      "Helvetica Neue",
//      Helvetica,
//      Arial,
//      "PingFang SC",
//      "Hiragino Sans GB",
//      "Heiti SC",
//      "Microsoft YaHei",
//      "WenQuanYi Micro Hei",
//      sans-serif;
//  -webkit-font-smoothing: antialiased;
//  -moz-osx-font-smoothing: grayscale;
//  text-align: center;
//  color: #2c3e50;
//}
.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 2000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4)
}
</style>