import Vue from 'vue'
import Vuex from 'vuex'
import { setCache, removeCache, filterAsideMenu } from 'U'
import main from './modules/main'
import cache from './modules/cache'
import animation from './animation'
import compose from './compose'
import contextmenu from './contextmenu'
import copy from './copy'
import event from './event'
import layer from './layer'
import snapshot from './snapshot'
import lock from './lock'
import {filterOperationMenu} from "U/index";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    userId:'',
    mock: false,
    token: '',
    userInfo: null,
    // 需要缓存的页面名称集合（注意：includes指令中配置的名称是组件内部name定义的名称，不是路由中配置的name）
    keepAliveComponents: [],
    // 页面参数缓存,防止页面刷新参数丢失
    paramsMap: {},
    loading: false,
    menuList: [],
    socketMessage: null,
    pageScrollInfo: {
      top: 0,
      left: 0,
    },

    sceneSystemData:{
      //水箱容积
      Tank_Volume:'',
      //高区供水压力
      High_Water_Pressure:'',
      //低区供水压力
      Low_Water_Pressure:'',
      //摄氏温度
      Celsius_Temperature:'',
      //相对湿度
      Relative_Humidity:'',
      //人员检测的状态
      WaterPumpHouse_Person_Status:'',
      //实时时间
      fmt_time:'',
      //机房门打开
      EngineRoom_DoorState:'',
      //机房跑水
      EngineRoom_OnFire:'',
      //送风机状态
      EngineRoom_FeedFanState:'',
      //排风机状态
      EngineRoom_ExhaustFanState:'',
      //潜水泵电源
      SubmersiblePump_Power:'',
      //潜水泵1运行状态
      SubmersiblePump_1_State:'',
      //潜水泵1故障
      SubmersiblePump_1_Fault:'',
      //潜水泵2运行状态
      SubmersiblePump_2_State:'',
      //潜水泵2故障
      SubmersiblePump_2_Fault:'',
      //水泵组1故障报警
      WaterPumpGroup_1_Fault:'',
      //水泵组1管网超压
      WaterPumpGroup_1_PipeNetworkOverpressure:'',
      //水泵组1管网失压
      WaterPumpGroup_1_PipeNetworkLosspressure:'',
      //水泵组1主泵1运行状态
      WaterPumpGroup_1_FirstMainPumpState:'',
      //水泵组1主泵2运行状态
      WaterPumpGroup_1_SecondMainPumpState:'',
      //水泵组1主泵3运行状态
      WaterPumpGroup_1_ThreeMainPumpState:'',
      //水泵组1稳压泵运行状态
      WaterPumpGroup_1_PressureStabilizingPumpState:'',
      //水泵组2故障报警
      WaterPumpGroup_2_Fault:'',
      //水泵组2管网超压
      WaterPumpGroup_2_PipeNetworkOverpressure:'',
      //水泵组2管网失压
      WaterPumpGroup_2_PipeNetworkLosspressure:'',
      //水泵组2主泵1运行状态
      WaterPumpGroup_2_FirstMainPumpState:'',
      //水泵组2主泵2运行状态
      WaterPumpGroup_2_SecondMainPumpState:'',
      //水泵组2主泵3运行状态
      WaterPumpGroup_2_ThreeMainPumpState:'',
      //水泵组2稳压泵运行状态
      WaterPumpGroup_2_PressureStabilizingPumpState:'',
      //水箱盖闭合状态
      Radiator_CapState:'',
      //水箱补水状态
      Radiator_SupplyState:'',
      //水箱溢水状态
      Radiator_OverflowState:'',
      //水箱缺水状态
      Radiator_LackState:'',
      //水箱水位状态
      Radiator_Overflow_Lack:'',
      //水箱风险去情况
      RadiatorCombination_describe:[],


      //人员检测的状态，1为有人，0为无人
      SwitchingHouse_Person_Status:'',
      //机房门打开
      IOT_SwitchingHouse_DoorState:'',
      //送风机状态
      IOT_SwitchingHouse_FeedFan:'',
      //排风机状态
      IOT_SwitchingHouse_ExhaustFan:'',
      //第一路进线柜开关状态
      SwitchingRoom_IncomingCabinetSwitch_1:'',
      //第二路进线柜开关状态
      SwitchingRoom_IncomingCabinetSwitch_2:'',
      //联络柜开关状态
      SwitchingRoom_ContactCabinetSwitch:'',
      //市/发电柜一市电开关状态
      SwitchingRoom_Group_1_ElectricSupplySwitch:'',
      //市/发电柜一发电机开关状态
      SwitchingRoom_Group_1_GenSwitch:'',
      //市/发电柜二市电开关状态
      SwitchingRoom_Group_2_ElectricSupplySwitch:'',
      //市/发电柜二发电开机关状态
      SwitchingRoom_Group_2_GenSwitch:'',
      //补偿柜一开关状态
      SwitchingRoom_1_CompensationCabinetSwitch:'',
      //补偿柜一补偿电容投入状态
      SwitchingRoom_1_CompensationCabinetCapacity:'',
      //补偿柜二开关状态
      SwitchingRoom_2_CompensationCabinetSwitch:'',
      //补偿柜二补偿电容投入状态
      SwitchingRoom_2_CompensationCabinetCapacity:'',
      //补偿柜三开关状态
      SwitchingRoom_3_CompensationCabinetSwitch:'',
      //补偿柜三补偿电容投入状态
      SwitchingRoom_3_CompensationCabinetCapacity:'',
      //补偿柜四开关状态
      SwitchingRoom_4_CompensationCabinetSwitch:'',
      //补偿柜四补偿电容投入状态
      SwitchingRoom_4_CompensationCabinetCapacity:'',
      //馈电回路组一电流A状态
      SwitchingRoom_1_A_FeederCircuit:'',
      //馈电回路组一电流B状态
      SwitchingRoom_1_B_FeederCircuit:'',
      //馈电回路组一电流C状态
      SwitchingRoom_1_C_FeederCircuit:'',
      //馈电回路组二电流A状态
      SwitchingRoom_2_A_FeederCircuit:'',
      //馈电回路组二电流B状态
      SwitchingRoom_2_B_FeederCircuit:'',
      //馈电回路组二电流C状态
      SwitchingRoom_2_C_FeederCircuit:'',
      //防潮灯状态
      SwitchingRoom_MoistureProofLamps:'',
      //第一路变压器温度
      SwitchingRoom_Transformer_1_Temp:'',
      //第一路变压器降温风扇
      SwitchingRoom_Transformer_1_Fan:'',
      //第二路变压器温度
      SwitchingRoom_Transformer_2_Temp:'',
      //第二路变压器降温风扇
      SwitchingRoom_Transformer_2_Fan:'',
      //第一路变压器负载率
      SwitchingRoom_Transformer_1_LoadRate:'',
      //第二路变压器负载率
      SwitchingRoom_Transformer_2_LoadRate:'',
      //第一路变压器电量参数
      SwitchingRoom_Transformer_1: {},
      SwitchingRoom_Transformer_1_Uab:'',
      SwitchingRoom_Transformer_1_Ubc:'',
      SwitchingRoom_Transformer_1_Uca:'',
      SwitchingRoom_Transformer_1_Ia:'',
      SwitchingRoom_Transformer_1_Ib:'',
      SwitchingRoom_Transformer_1_Ic:'',
      SwitchingRoom_Transformer_1_P:'',
      SwitchingRoom_Transformer_1_COS:'',
      SwitchingRoom_Transformer_1_F:'',
      SwitchingRoom_Transformer_1_Q:'',
      //第二路变压器电量参数
      SwitchingRoom_Transformer_2: {},
      SwitchingRoom_Transformer_2_Uab:'',
      SwitchingRoom_Transformer_2_Ubc:'',
      SwitchingRoom_Transformer_2_Uca:'',
      SwitchingRoom_Transformer_2_Ia:'',
      SwitchingRoom_Transformer_2_Ib:'',
      SwitchingRoom_Transformer_2_Ic:'',
      SwitchingRoom_Transformer_2_P:'',
      SwitchingRoom_Transformer_2_COS:'',
      SwitchingRoom_Transformer_2_F:'',
      SwitchingRoom_Transformer_2_Q:'',
    },

    ...animation.state,
    ...compose.state,
    ...contextmenu.state,
    ...copy.state,
    ...event.state,
    ...layer.state,
    ...snapshot.state,
    ...lock.state,

    editMode: 'edit', // 编辑器模式 edit preview
    canvasStyleData: { // 页面全局数据
      width: 1200,
      height: 760,
      scale: 100,
      color: '#000',
      opacity: 1,
      background: '#fff',
      fontSize: 14,
    },
    isInEdiotr: false, // 是否在编辑器中，用于判断复制、粘贴组件时是否生效，如果在编辑器外，则无视这些操作
    componentData: [], // 画布组件数据
    curComponent: null,
    curComponentIndex: null,
    // 点击画布时是否点中组件，主要用于取消选中组件用。
    // 如果没点中组件，并且在画布空白处弹起鼠标，则取消当前组件的选中状态
    isClickComponent: false,
  },
  getters: {
    // getSocketMessage:state => state.socketMessage,
    getUserId: state => state.userId,
    asideMenuTree: state => {
      let menuList = JSON.parse(JSON.stringify(state.menuList))
      return filterAsideMenu(menuList)
    },
    operationMenuTree: state => {
      let menuList = JSON.parse(JSON.stringify(state.menuList))
      return filterOperationMenu(menuList)
    },
    uploadHeaders: state => {
      return {
        Authorization: state.token
      }
    },
    uploadData: () => {
      return {
        folder: 'pc'
      }
    },
    uploadBasemap: () => {
      return {
        folder: 'basemap',
      }
    },
    uploadSceneInfoPic: () => {
      return {
        folder: 'sceneinfopic',
      }
    },
    uploadFiles: () => {
      return {
        folder: 'applicationFile',
      }
    },
    uploadSupervise: () => {
      return {
        folder: 'superviseFile',
      }
    },
    uploadFacilityEquipment: () => {
      return {
        folder: 'facilityEquipment',
      }
    },
    uploadBusinessManagement: () => {
      return {
        folder: 'businessManagement',
      }
    },
    uploadAnnexData: () => {
      return {
        folder: 'annex'
      }
    },
    uploadEnterpriseLicenseData: () => {
      return {
        folder: 'enterpriseLicense'
      }
    },
    uploadFourFilesData: () => {
      return {
        folder: 'four_files'
      }
    },
    uploadPropertyFeesData: () => {
      return {
        folder: 'propertyFees'
      }
    },
    uploadPublicRevenueData: () => {
      return {
        folder: 'publicRevenue'
      }
    },
    uploadSpecialMaintenanceFundsData: () => {
      return {
        folder: 'specialMaintenanceFunds'
      }
    },
  },
  mutations: {
    ...animation.mutations,
    ...compose.mutations,
    ...contextmenu.mutations,
    ...copy.mutations,
    ...event.mutations,
    ...layer.mutations,
    ...snapshot.mutations,
    ...lock.mutations,

    setClickComponentStatus(state, status) {
      state.isClickComponent = status
    },

    setEditMode(state, mode) {
      state.editMode = mode
    },

    setInEditorStatus(state, status) {
      state.isInEdiotr = status
    },

    setCanvasStyle(state, style) {
      state.canvasStyleData = style
    },

    setCurComponent(state, { component, index }) {
      state.curComponent = component
      state.curComponentIndex = index
    },

    setShapeStyle({ curComponent }, { top, left, width, height, rotate }) {
      if (top) curComponent.style.top = Math.round(top)
      if (left) curComponent.style.left = Math.round(left)
      if (width) curComponent.style.width = Math.round(width)
      if (height) curComponent.style.height = Math.round(height)
      if (rotate) curComponent.style.rotate = Math.round(rotate)
    },

    setShapeSingleStyle({ curComponent }, { key, value }) {
      curComponent.style[key] = value
    },

    setComponentData(state, componentData = []) {
      Vue.set(state, 'componentData', componentData)
    },

    addComponent(state, { component, index }) {
      if (index !== undefined) {
        state.componentData.splice(index, 0, component)
      } else {
        state.componentData.push(component)
      }
    },

    deleteComponent(state, index) {
      if (index === undefined) {
        index = state.curComponentIndex
      }

      if (index == state.curComponentIndex) {
        state.curComponentIndex = null
        state.curComponent = null
      }

      if (/\d/.test(index)) {
        state.componentData.splice(index, 1)
      }
    },

    setUserId(state,userId){
      state.userId = userId;
    },
    setCacheDatas(state, cacheDatas) {
      // 恢复缓存数据token、userInfo等
      if(cacheDatas) {
        for(let key in cacheDatas) {
          state[key] = cacheDatas[key]
        }
      }
    },
    setToken(state, token) {
      if(token) {
        state.token = token;
        setCache('token', token);
      }else {
        state.token = '';
        removeCache('token');
      }
    },
    setUserInfo: (state, userInfo) => {
      if (userInfo) {
        state.userInfo = userInfo;
        setCache("userInfo", userInfo);
      } else {
        state.userInfo = null;
        removeCache("userInfo");
      }
    },
    addKeepAlive(state, name) {
      let arr = state.keepAliveComponents.map(item => {
        return item;
      });
      arr.push(name);
      state.keepAliveComponents = arr;
    },
    delKeepAlive(state, name) {
      let arr = state.keepAliveComponents.filter(item => {
        return item != name;
      })
      state.keepAliveComponents = arr;
    },
    setPageParams: (state, payload) => {
      state.paramsMap[payload.path] = payload.params;
      setCache("paramsMap", state.paramsMap);
    },
    setParamsMap: (state, paramsMap) => {
      state.paramsMap = paramsMap;
      setCache("paramsMap", paramsMap);
    },
    setLoading(state, loading) {
      state.loading = loading;
    },
    setMenuList(state, menuList) {
      state.menuList = menuList;
      setCache("menuList", menuList);
    },
    setSocketMessage(state, socketMessage) {
      state.socketMessage = socketMessage;
    },
    setPageScrollInfo(state, pageScrollInfo) {
      state.pageScrollInfo = pageScrollInfo;
    },
  },
  actions: {
  },
  modules: {
    main,
    cache,
  }
})
