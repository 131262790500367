import { getItemFromArrayByKey } from 'U'

export default {
  namespaced: true,
  state: {
    areaMap: {},
    deviceMap: {},
    dictMap: {},
    deptMap: {},
    liftpropertyMap: {},
  },
  mutations: {
    addArea(state, payload) {
      state.areaMap[payload.key] = payload.data;
    },
    resetArea(state) {
      state.areaMap = {};
    },
    addDevice(state, payload) {
      state.deviceMap[payload.key] = payload.data;
    },
    resetDevice(state) {
      state.deviceMap = {};
    },
    addDict(state, payload) {
      state.dictMap[payload.key] = payload.data;
    },
    resetDict(state) {
      state.dictMap = {};
    },
    addDept(state, payload) {
      state.deptMap[payload.key] = payload.data;
    },
    resetDept(state) {
      state.deptMap = {};
    },
    addProperty(state, payload) {
      state.liftpropertyMap[payload.key] = payload.data;
    },
    resetProperty(state) {
      state.liftpropertyMap = {};
    },
    addLiftproperty(state, payload) {
      state.liftpropertyMap[payload.key] = payload.data;
    },
    resetLiftproperty(state) {
      state.liftpropertyMap = {};
    },
  },
}