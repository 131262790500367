import Vue from 'vue';
import PageContainer from './PageContainer.vue';
import myMessage from './message.vue';

Vue.component('page-container', PageContainer);

const MessageBox = Vue.extend(myMessage)

myMessage.install = function (options, type) {
    if (options === undefined || options === null) {
        options = {
            content: ''
        }
    } else if (typeof options === 'string' || typeof options === 'number') {
        options = {
            content: options
        }
        if (type !== undefined && options !== null) {
            options.type = type
        }
    }

    let instance = new MessageBox({
        data: options
    }).$mount()

    document.body.appendChild(instance.$el)

    Vue.nextTick(() => {
        instance.visible = true
    })
}

export default myMessage