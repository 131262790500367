// 公共样式
export const commonStyle = {
    rotate: 0,
    opacity: 1,
}

export const commonAttr = {
    animations: [],
    events: {},
    groupStyle: {}, // 当一个组件成为 Group 的子组件时使用
    isLock: false, // 是否锁定组件
    collapseName: 'style', // 编辑组件时记录当前使用的是哪个折叠面板，再次回来时恢复上次打开的折叠面板，优化用户体验
    linkage: {
        duration: 0, // 过渡持续时间
        data: [ // 组件联动
            {
                id: '', // 联动的组件id
                label: '', // 联动的组件名称
                parameter:'',//联动组件关联参数
                iconid:'',//联动组件图标id
                event: '', // 监听事件
                style: [{ key: '', value: '' }], // 监听的事件触发时，需要改变的属性
            },
        ],
    },
}

// 编辑器左侧组件列表
const list = [
    {
        component: 'VText',
        label: '文字',
        propValue: '双击编辑文字',
        icon: require('../assets/image/scene/wenzi.png'),
        request: {
            method: 'GET',
            data: [],
            url: '',
            series: false, // 是否定时发送请求
            time: 1000, // 定时更新时间
            paramType: '', // string object array
            requestCount: 0, // 请求次数限制，0 为无限
        },
        style: {
            width: 200,
            height: 28,
            fontSize: '',
            fontWeight: 400,
            lineHeight: '',
            letterSpacing: 0,
            textAlign: '',
            color: '',
        },
    },
    {
        component: 'VButton',
        label: '按钮',
        propValue: '按钮',
        icon: require('../assets/image/scene/anniu.png'),
        style: {
            width: 100,
            height: 34,
            borderWidth: 1,
            borderColor: '',
            borderRadius: '',
            fontSize: '',
            fontWeight: 400,
            lineHeight: '',
            letterSpacing: 0,
            textAlign: '',
            color: '',
            backgroundColor: '',
        },
    },
    {
        component: 'FullScreen',
        label: '全屏',
        icon: require('../assets/image/scene/fullscreen.png'),
        style: {
            width: 30,
            height: 30,
        },
    },
    {
        component: 'DateClock',
        label: '时钟',
        icon: require('../assets/image/scene/dateclock.png'),
        style: {
            width: 150,
            height: 80,
        },
    },
    // {
    //     component: 'Picture',
    //     label: '图片',
    //     icon: require('../assets/image/scene/tupian.png'),
    //     propValue: {
    //         url: require('@/assets/title.jpg'),
    //         flip: {
    //             horizontal: false,
    //             vertical: false,
    //         },
    //     },
    //     style: {
    //         width: 300,
    //         height: 200,
    //         borderRadius: '',
    //     },
    // },
    {
        component: 'RectShape',
        label: '矩形',
        propValue: '',
        icon: require('../assets/image/scene/juxing.png'),
        style: {
            width: 100,
            height: 100,
            fontSize: '',
            fontWeight: 400,
            lineHeight: '',
            letterSpacing: 0,
            textAlign: 'center',
            color: '',
            borderColor: '#000',
            borderWidth: 1,
            backgroundColor: '',
            borderStyle: 'solid',
            borderRadius: '',
            verticalAlign: 'middle',
        },
    },
    {
        component: 'LineShape',
        label: '直线',
        propValue: '',
        icon: require('../assets/image/scene/zhixian.png'),
        style: {
            width: 200,
            height: 2,
            backgroundColor: '#000',
        },
    },
    {
        component: 'CircleShape',
        label: '圆形',
        propValue: '',
        icon: require('../assets/image/scene/yuanxing.png'),
        style: {
            width: 80,
            height: 80,
            fontSize: '',
            fontWeight: 400,
            lineHeight: '',
            letterSpacing: 0,
            textAlign: 'center',
            color: '',
            borderColor: '#000',
            borderWidth: 1,
            backgroundColor: '',
            borderStyle: 'solid',
            borderRadius: '',
            verticalAlign: 'middle',
        },
    },
    {
        component: 'BanYuan',
        label: '半圆',
        icon: require('../assets/image/scene/banyuan.png'),
        propValue: {
            url: require('@/assets/image/scene/banyuan.png'),
            flip: {
                horizontal: false,
                vertical: false,
            },
        },
        style: {
            width: 80,
            height: 80,
            borderRadius: '',
        },
    },
    {
        component: 'SVGStar',
        label: '星形',
        icon: require('../assets/image/scene/xingxing.png'),
        propValue: '',
        style: {
            width: 80,
            height: 80,
            fontSize: '',
            fontWeight: 400,
            lineHeight: '',
            letterSpacing: 0,
            textAlign: 'center',
            color: '',
            borderColor: '#000',
            backgroundColor: 'rgba(255, 255, 255, 1)',
        },
    },
    {
        component: 'SVGTriangle',
        label: '三角形',
        icon: require('../assets/image/scene/sanjiaoxing.png'),
        propValue: '',
        style: {
            width: 80,
            height: 80,
            fontSize: '',
            fontWeight: 400,
            lineHeight: '',
            letterSpacing: 0,
            textAlign: 'center',
            color: '',
            borderColor: '#000',
            backgroundColor: 'rgba(255, 255, 255, 1)',
        },
    },
    {
        component: 'VTable',
        label: '表格',
        icon: require('../assets/image/scene/biaoge.png'),
        propValue: {
            data: [
                ['状态', '故障', '风险'],
                ['正常', '无', '无'],
            ],
            stripe: true,
            thBold: true,
        },
        request: {
            method: 'GET',
            data: [],
            url: '',
            series: false,
            time: 1000,
            paramType: '', // string object array
            requestCount: 0, // 请求次数限制，0 为无限
        },
        style: {
            width: 600,
            height: 200,
            fontSize: '',
            fontWeight: 400,
            textAlign: 'center',
            color: '',
            backgroundColor: 'rgba(255, 255, 255, 1)',
        },
    },
    {
        component: 'WenShiDu',
        label: '温湿度',
        icon: require('../assets/image/scene/wenshidu.png'),
        propValue: {
            url: require('@/assets/image/scene/wenshidu.png'),
            flip: {
                horizontal: false,
                vertical: false,
            },
        },
        style: {
            width: 150,
            height: 100,
            borderRadius: '',
        },
    },
    {
        component: 'QiGuan',
        label: '气罐',
        icon: require('../assets/image/scene/qiguan.png'),
        propValue: {
            url: require('@/assets/image/scene/qiguan.png'),
            flip: {
                horizontal: false,
                vertical: false,
            },
        },
        style: {
            width: 100,
            height: 100,
            borderRadius: '',
        },
    },
    {
        component: 'FengJiStop',
        label: '风机停',
        icon: require('../assets/image/scene/fengshan-stop.png'),
        propValue: {
            url: require('@/assets/image/scene/fengshan-stop.png'),
            flip: {
                horizontal: false,
                vertical: false,
            },
        },
        style: {
            width: 100,
            height: 100,
            borderRadius: '',
        },
    },
    {
        component: 'FengJiRun',
        label: '风机转',
        icon: require('../assets/image/scene/fengshan-run.png'),
        propValue: {
            url: require('@/assets/image/scene/fengshan-run.png'),
            flip: {
                horizontal: false,
                vertical: false,
            },
        },
        style: {
            width: 100,
            height: 100,
            borderRadius: '',
        },
    },
    {
        component: 'People',
        label: '人员',
        icon: require('../assets/image/scene/people.png'),
        propValue: {
            url: require('@/assets/image/scene/people.png'),
            flip: {
                horizontal: false,
                vertical: false,
            },
        },
        style: {
            width: 60,
            height: 60,
            borderRadius: '',
        },
    },
    {
        component: 'YouRenJinRu',
        label: '有人',
        icon: require('../assets/image/scene/yourenjinru.png'),
        propValue: {
            url: require('@/assets/image/scene/yourenjinru.png'),
            flip: {
                horizontal: false,
                vertical: false,
            },
        },
        style: {
            width: 60,
            height: 60,
            borderRadius: '',
        },
    },
    {
        component: 'ShiXuTu',
        label: '运行图',
        icon: require('../assets/image/scene/shixutu.png'),
        propValue: {
            url: require('@/assets/image/scene/shixutu.png'),
            flip: {
                horizontal: false,
                vertical: false,
            },
        },
        style: {
            width: 100,
            height: 100,
            borderRadius: '',
        },
    },
    {
        component: 'LightClose',
        label: '灯关闭',
        icon: require('../assets/image/scene/light1.png'),
        propValue: {
            url: require('@/assets/image/scene/light1.png'),
            flip: {
                horizontal: false,
                vertical: false,
            },
        },
        style: {
            width: 50,
            height: 50,
            borderRadius: '',
        },
    },
    {
        component: 'LightOpen',
        label: '灯打开',
        icon: require('../assets/image/scene/light2.png'),
        propValue: {
            url: require('@/assets/image/scene/light2.png'),
            flip: {
                horizontal: false,
                vertical: false,
            },
        },
        style: {
            width: 50,
            height: 50,
            borderRadius: '',
        },
    },
]

for (let i = 0, len = list.length; i < len; i++) {
    const item = list[i]
    item.style = { ...commonStyle, ...item.style }
    list[i] = { ...commonAttr, ...item }
}

export default list
